<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#13736f"
        spinner="spinner" />
      <v-flex xs12 text-center>
        <v-layout v-if="cart.length > 0" class="hidden-md-and-up" wrap>
          <template>
            <v-flex pt-4 xs12>
              <v-layout wrap>
                <v-flex xs12 sm2 class="header-cell">
                  Product
                </v-flex>
                <v-flex xs12 sm3 class="header-cell">
                  Price
                </v-flex>
                <v-flex xs12 sm2 class="header-cell">
                  Weight
                </v-flex>
                <v-flex xs12 sm2 class="header-cell">
                  Quantity
                </v-flex>
                <v-flex xs12 sm3 class="header-cell">
                  Subtotal
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm12 v-for="(item, i) in cart" :key="i" class="data-row">
                  <v-avatar size="60px">
                    <v-img :src="mediaURLnew + item.productId.photos[0]"></v-img>
                  </v-avatar>
                  <div class="data-cell">
                    <span class="dmsansregular">{{ item.productId.name }}</span>
                    <p class="price">
                      Rs {{ item.productId.price * item.quantity }}
                    </p>
                  </div>
                  <div class="data-cell">
                    Rs {{ item.productId.price }}
                  </div>
                  <div class="data-cell">
                    {{ item.productId.weight }} {{ item.productId.weightType }}
                  </div>
                  <div class="data-cell">
                    <v-btn elevation="0" x-small class="no-uppercase" height="36px" @click="decrementQuantity(item)"
                      :disabled="item.quantity === 1">
                      <v-icon size="110%" center>mdi-minus</v-icon>
                    </v-btn>
                    <span class="dmsansregular">&nbsp;{{ item.quantity }}&nbsp;</span>
                    <v-btn elevation="0" class="no-uppercase" x-small height="36px"
                      :disabled="item.quantity === item.productId.stock" @click="incrementQuantity(item)">
                      <v-icon size="110%" center>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div class="data-cell">
                    Rs {{ item.productId.price * item.quantity }}
                    <v-icon @click="(removedialogue = true), (curid = item._id)" style="cursor: pointer" color="black">
                      mdi-delete-outline
                    </v-icon>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </template>


        </v-layout>
        <v-layout v-if="cart.length > 0" class="hidden-sm-and-down" wrap justify-center>
          <v-flex xs10 pt-3 text-left>
            <span style="
                font-family: opensansregular;
                color: black;
                font-size: 30px;
                font-weight: bold;
              ">
              Shopping Cart</span>
          </v-flex>

          <v-flex pt-4 v-if="cart.length > 0" xs12 sm9 text-center>
            <v-layout wrap style="background-color: #f7f7f7">
              <v-flex pa-3 text-center xs12 sm2 class="dmsansregular"
                style="color: black; font-size: 16px; font-weight: 600">
                Product
              </v-flex>
              <v-flex xs12 sm3 pt-3 pl-12 pb-3 class="dmsansregular"
                style="color: black; font-size: 16px; font-weight: 600">
                Price
              </v-flex>
              <v-flex xs12 sm2 pt-3 pb-3 class="dmsansregular" style="color: black; font-size: 16px; font-weight: 600">
                Weight
              </v-flex>
              <v-flex xs12 sm2 pt-3 pb-3 class="dmsansregular" style="color: black; font-size: 16px; font-weight: 600">
                Quantity
              </v-flex>
              <v-flex xs12 sm3 pt-3 pb-3 class="dmsansregular" style="color: black; font-size: 16px; font-weight: 600">
                Subtotal
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex pt-4 pb-3 xs9 v-for="(item, i) in cart" :key="i" text-left>
            <v-layout v-if="cart.length > 0" wrap style="
                background-color: white;
                box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);
              ">
              <v-flex pa-3 xs3>
                &nbsp;
                <v-avatar size="60px">
                  <v-img :src="mediaURLnew + item.productId.photos[0]"></v-img>
                </v-avatar>
                &nbsp; &nbsp;
                <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 600">
                  {{ item.productId.name }}
                </span></v-flex>
              <v-flex xs2 pt-8 pr-11 text-center pb-3 class="dmsansregular"
                style="color: black; font-size: 14px; font-weight: 600">
                <span> Rs&nbsp;</span>
                <span style="color: black; font-size: 14px; font-weight: 500"> {{ item.productId.price }}</span>

              </v-flex>

              <v-flex xs2 text-center pt-8 pb-3 class="dmsansregular"
                style="color: black; font-size: 14px; font-weight: 600">
                <span style="color: black; font-size: 14px; font-weight: 500">
                  {{ item.productId.weight }}
                </span>

                {{ item.productId.weightType }}</v-flex>

              <v-flex xs2 text-center pt-6 pb-3>
                <v-btn elevation="0" x-small class="no-uppercase" height="36px" @click="decrementQuantity(item)"
                  color="#F2F2F2" style="width: 2px; padding: 0">
                  <v-icon size="110%" center>mdi-minus</v-icon>
                </v-btn>
                &nbsp;
                <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 600">&nbsp;

                  {{ item.quantity }}
                  &nbsp;</span>
                &nbsp;

                <v-btn elevation="0" class="no-uppercase" x-small height="36px"
                  :disabled="item.quantity === item.productId.stock" @click="incrementQuantity(item)" color="#F2F2F2">
                  <v-icon size="110%" center>mdi-plus</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs3 text-center pl-9 pt-8 pb-3>
                <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 600">
                  Rs
                  <span style="color: black; font-size: 14px; font-weight: 500">
                    {{ item.productId.price * item.quantity }}

                  </span>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;

                <v-icon @click="(removedialogue = true), (curid = item._id)" style="cursor: pointer"
                  color="black">mdi-delete-outline</v-icon>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center v-if="cart.length > 0">
          <v-flex xs11 lg5 pt-6>
            <v-layout wrap pt-4 pb-4 style="background-color: #f7f7f7">
              <v-flex xs5 pl-5 text-left class="dmsansregular"
                style="color: black; font-size: 16px; font-weight: 600">Addess</v-flex>

              <v-flex xs6 @click="dialog2 = true" text-right class="dmsansregular" style="
                  color: #29807c;
                  font-size: 14px;
                  font-weight: 600;
                  cursor: pointer;
                ">+ ADD ADDRESS
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-layout v-if="list.length > 0" wrap fill-height justify-center align-center style="
    background-color: white;
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);
  ">
                <v-flex xs3 text-center align-center class="dmsansregular"
                  style="color: black; font-size: 14px; font-weight: 500">
                  <span style="color: black; font-size: 14px; font-weight: 600">{{ deliveryAddress.name }}</span>
                  <br />
                  {{ deliveryAddress.pincode }} {{ deliveryAddress.city }} {{ deliveryAddress.state }}
                  <span>{{ deliveryAddress.phone }}</span>
                  <br />
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 500">
                    {{ deliveryAddress.address }}
                  </span>
                </v-flex>
                <v-flex xs3 text-center align-center class="dmsansregular"
                  style="color: black; font-size: 14px; font-weight: 500">
                  {{ deliveryAddress.email }}
                </v-flex>

                <v-flex xs3 text-center align-center class="dmsansregular"
                  style="color: black; font-size: 14px; font-weight: 500">
                  {{ deliveryAddress.landmark }}
                </v-flex>

                <v-flex xs2 text-center align-center class="dmsansregular"
                  style="color: black; font-size: 14px; font-weight: 500">
                  {{ deliveryAddress.routeDescription }}
                </v-flex>
              </v-layout>

              <v-layout v-if="list.length === 0" wrap justify-center
                style="background-color: white; box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);">
                <v-flex xs12 text-center pt-6 pb-3 class="dmsansregular"
                  style="color: black; font-size: 14px; font-weight: 600">
                  No addresses available
                </v-flex>
              </v-layout>

              <v-layout wrap justify-center style="
                  background-color: white;
                  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);
                ">
                <v-flex xs11 text-left pt-2 pb-3 class="dmsansregular"
                  style="color: black; font-size: 14px; font-weight: 600">
                  <template v-if="list.length > 0">
                    <v-expansion-panels>
                      <v-expansion-panel @input="clearSelectedAddress">
                        <v-expansion-panel-header pa-0>
                          Change Address
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-layout wrap pt-2 v-for="(item, i) in list" :key="i">
                            <v-flex xs12>
                              <v-card class="pa-3">
                                <v-layout wrap justify-center class="dmsansregular" style="
                                    color: black;
                                    font-size: 14px;
                                    font-weight: 400;
                                  ">
                                  <v-flex xs8 pl-6 text-center>
                                    <span class="dmsansregular" style="
                                    color: black;
                                    font-size: 14px;
                                    font-weight: 600;
                                  ">{{ item.name }}</span>
                                    <br />

                                    <span>
                                      {{ item.address }}
                                      <br />
                                    </span>

                                    {{ item.city }}

                                    {{ item.state }}
                                    -

                                    {{ item.pincode }}<br />
                                    <span>Contact : </span><span>{{ item.phone }}</span>,&nbsp;

                                    <span>{{ item.email }}</span>
                                  </v-flex>
                                  <v-flex xs1 pt-10 pr-8 style="cursor: pointer"
                                    @click="(editdailog = true), (curid = item)">
                                    <v-icon size="130%" color="#13736f">mdi-pencil</v-icon>
                                  </v-flex>

                                  <v-flex xs1 pt-10 pl-4 style="cursor: pointer" @click="
                                    (deletedialog = true), (curid = item._id)
                                    ">
                                    <v-icon color="red" size="130%">mdi-delete</v-icon>
                                  </v-flex>

                                  <v-flex xs2 pl-7 pt-5>
                                    <v-radio-group v-model="selectedAddressIndex">
                                      <v-radio :key="i" v-if="item.isCurrent" color="#13736f" :value="i"
                                        @click="editDeliveryaddress(item._id)"></v-radio>

                                      <v-radio :key="i" v-else color="#13736f" :value="i"
                                        @click="editDeliveryaddress(item._id)"></v-radio>
                                    </v-radio-group>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template> </v-flex></v-layout>
            </v-flex>
          </v-flex>

          <v-flex xs11 lg4 pl-md-16 pt-6>
            <v-layout pb-10 wrap justify-center style="background-color: #f7f7f7">
              <v-flex xs11 lg9 pt-7 text-left>
                <span class="dmsansregular" style="color: black; font-size: 18px; font-weight: 600">
                  Order Details</span>
              </v-flex>

              <v-flex xs5 pt-4 text-left>
                <span class="dmsansregular" style="color: black; font-size: 16px; font-weight: 600">
                  Bag Total
                </span>
              </v-flex>
              <v-flex xs4 pt-4 text-left pl-8>
                <span class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 600">
                    Rs </span> {{ totalAmount }}</span>
              </v-flex>

              <v-flex xs5 pt-4 text-left>
                <span class="dmsansregular" style="color: black; font-size: 16px; font-weight: 600">
                  Delivery Charge</span>
              </v-flex>

              <v-flex xs4 pt-4 pl-8 text-left>
                <span class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 600">
                    Rs </span> {{ deliveryCharge }}</span>
              </v-flex>
              <v-flex xs5 pt-4 text-left>
                <span class="dmsansregular" style="color: black; font-size: 16px; font-weight: 600">
                  Total Tax</span>
              </v-flex>

              <v-flex xs4 pt-4 text-left pl-8>
                <span class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 600">
                    Rs </span> {{ totalTax }}</span>
              </v-flex>
              <v-flex xs5 pt-4 text-left>
                <span class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 700">
                  Amount Payable</span>
              </v-flex>

              <v-flex xs4 pt-4 pl-8 text-left>
                <span class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 600">
                    Rs </span> {{ amountPayable }}</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs11 lg12 pt-3 pb-7>
                <v-btn @click="proceedCheckout()" class="no-uppercase" block color="#E96125"><span style="
                      font-family: opensansregular;
                      font-size: 14px;
                      color: #ffffff;
                      letter-spacing: 1px;
                      cursor: pointer;
                    ">&nbsp;Proceed to Checkout &nbsp;
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else justify-center>
          <v-flex xs10 lg4 class="d-flex align-center justify-center">
            <v-layout wrap justify-center>
              <v-flex xs9>
                <v-img contain src="../../assets/Images/cart3.png"></v-img>

              </v-flex>
              <v-flex xs4 pb-14>
                <v-btn height="40px" class="no-uppercase" @click="$router.push('/DashboardPage')" dark block
                  color="#13736f"><span style="
                                              font-family: mainfont;
                                              font-size: 15px;
                                              color: white;
                                              letter-spacing: 1px;
                                              cursor: pointer;
                                            ">Go Home
                  </span>
                </v-btn>





              </v-flex>

            </v-layout>
          </v-flex>
        </v-layout>

        <v-dialog :retain-focus="true" persistent v-model="dialog2" max-width="650px">
          <v-card class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
            <v-layout wrap justify-center>
              <v-flex><v-layout pa-4 wrap justify-center>
                  <v-flex xs12 text-right>
                    <v-icon @click="dialog2 = false" color="red" size="150%" dark>
                      mdi-close
                    </v-icon>
                  </v-flex>

                  <v-flex xs5 pt-4 pr-5>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Name</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="name"
                        hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pl-5 pt-4>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Email</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense v-model="email" hide-details
                        :rules="emailRules"></v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pt-3 pr-5>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Mobile Number</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="phone"
                        hide-details :rules="phoneRules"></v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pl-5 pt-3>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Pincode</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="number"
                        v-model="pincode" hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pt-3 pr-5>
                    <span style="color: black; font-size: 16px; font-weight: bold">City</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="city"
                        hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pl-5 pt-3>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      State</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="state"
                        hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pt-3 pr-5>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Landmark</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="landmark"
                        hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pl-5 pt-3>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Route Description</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text"
                        v-model="routeDescription" hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs10 pt-3>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Address</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" type="text" v-model="address"
                        hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs10 text-right pt-4>
                    <v-btn v-if="shouldShowVerifyButton && shouldShowVerifyButton2" @click="validateInput()" dark width="100px" color="#13736f">

                      <span style="color: white; font-size: 14px"> Add </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>

        <v-dialog :retain-focus="true" persistent v-model="removedialogue" max-width="600px">
          <v-card>
            <v-card-title text-center class="dmsansregular" style="color: black; font-size: 16px; font-weight: lighter">
              <v-layout wrap justify-center>
                <v-flex xs12 text-center>
                  Are you sure you want to remove this product from Cart?
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" text @click="removedialogue = false" class="dmsansregular no-uppercase"
                style="color: black; font-size: 16px; font-weight: lighter">Cancel</v-btn>
              <v-btn color="red" class="dmsansregular no-uppercase"
                style="color: black; font-size: 16px; font-weight: lighter" text @click="removeItem()">Ok</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog :retain-focus="true" persistent v-model="editdailog" max-width="650px">
          <v-card class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
            <v-layout wrap justify-center>
              <v-flex><v-layout pa-4 wrap justify-center>
                  <v-flex xs12 text-right>
                    <v-icon @click="editdailog = false" color="red" size="150%" dark>
                      mdi-close
                    </v-icon>
                  </v-flex>

                  <v-flex xs5 pt-4 pr-5>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Name</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text"
                        v-model="curid.name" hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pl-5 pt-4>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Email</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense v-model="curid.email"
                        hide-details :rules="emailRules"></v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pt-3 pr-5>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Mobile Number</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text"
                        v-model="curid.phone" hide-details :rules="phoneRules"></v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pl-5 pt-3>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Pincode</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="number"
                        v-model="curid.pincode" hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pt-3 pr-5>
                    <span style="color: black; font-size: 16px; font-weight: bold">City</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text"
                        v-model="curid.city" hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pl-5 pt-3>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      State</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text"
                        v-model="curid.state" hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pt-3 pr-5>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Landmark</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text"
                        v-model="curid.landmark" hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs5 pl-5 pt-3>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Route Description</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" dense type="text"
                        v-model="curid.routeDescription" hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs10 pt-3>
                    <span style="color: black; font-size: 16px; font-weight: bold">
                      Address</span>
                    <v-form @submit.prevent="validateInput">
                      <v-text-field color="#13736f" outlined background-color="white" type="text" v-model="curid.address"
                        hide-details>
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs10 text-right pt-4>
                    <v-btn @click="editAddress()" dark width="100px" color="#13736f">
                      <span style="color: white; font-size: 14px"> Edit </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>

        <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="600px">
          <v-card>
            <v-card-title class="dmsansregular" style="color: black; font-size: 16px; font-weight: lighter">
              <v-layout wrap justify-center>
                <v-flex xs12 text-center>
                  Are you sure you want to remove this Address?
                </v-flex>
              </v-layout>
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" text @click="deletedialog = false" class="dmsansregular no-uppercase"
                style="color: black; font-size: 16px; font-weight: lighter">Cancel</v-btn>
              <v-btn color="red" class="dmsansregular no-uppercase"
                style="color: black; font-size: 16px; font-weight: lighter" text @click="deleteItem()">Ok</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showNoStockDialog" max-width="300">
          <v-card>
            <v-card-title class="headline">Out of Stock</v-card-title>
            <v-card-text>
              Sorry, there is no stock left for this item.
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="closeNoStockDialog">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
    
    
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      currentpage: 1,
      showSnackBar: false,
      msg: null,
      deliveryAddress: {},
      list: [],
      editdailog: false,
      deletedialog: false,
      showid: {},
      curid: [],
      totalTax: null,
      totalAmount: null,
      deliveryCharge: null,
      amountPayable: null,
      removedialogue: false,
      selectedAddress: {},
      delivery: {},
      limit: 10,
      selectedAddressIndex: -1,
      appLoading: false,
      pages: 0,
      orderId: {},
      icon: null,
      // emailRules: [
      //   (v) => !!v || "Email is required",
      //   (v) => /.+@.+\..+/.test(v) || "Valid email is required",
      // ],
      // phoneRules: [
      //   (v) => !!v || "Phone number is required",
      //   (v) => /^[0-9]+$/.test(v) || "Only digits are allowed",
      //   (v) => (v && v.length === 10) || "Phone number must be 10 digits",
      // ],
      name: null,
      email: null,
      phone: null,
      address: null,
      pincode: null,
      city: null,
      state: null,
      landmark: null,
      routeDescription: null,
      showNoStockDialog: false,

      cart: [],
      item: [],

      dialog2: false,
    };
  },
  computed: {
      shouldShowVerifyButton() {
        return this.phoneRules.every(rule => rule(this.phone) === true);
      },
      shouldShowVerifyButton2() {
        return this.emailRules.every(rule => rule(this.email) === true);
      },
      phoneRules() {
        return [
          (v) => !!v || "Phone number is required",
          (v) => /^[0-9]+$/.test(v) || "Only digits are allowed",
          (v) => (v && v.length === 10) || "Phone number must be 10 digits",
        ];
      },
      emailRules() {
        return [
          (v) => !!v || "Email is required",
          (v) => /.+@.+\..+/.test(v) || "Valid email is required",
        ];
      },
    },
  mounted() {
    this.getCart();
  },
  beforeMount() {
    this.getAddress();
  },
  loadData() {
    this.getAddress();
    this.getCart();
  },
  methods: {
    clearSelectedAddress() {
      this.selectedAddressIndex = -1;
      this.selectedAddress = null;
    },
    deleteItem() {
      axios({
        url: "/deliveryAddress/delete/" + this.curid,
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    removeItem() {
      axios({
        url: "/cart/remove/" + this.curid,
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.removedialogue = false;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    incrementQuantity(item) {
      if (item.quantity === item.productId.stock) {
        this.showNoStockDialog = true;
      } else {
        item.quantity++;
        this.editCart(item);
      }
    },
    closeNoStockDialog() {
      this.showNoStockDialog = false;
    },
    decrementQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--;
        this.editCart(item);
      }
    },

    proceedCheckout() {
      axios({
        url: "/user/placeorder",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          deliveryAddressId: this.deliveryAddress._id,
          orderTotal: this.amountPayable,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            this.orderId = response.data.id;
            console.log("id is", this.orderId)
            this.confirmOrder();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    confirmOrder() {
      axios({
        url: "/order/status/placed",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.orderId,

        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push({
              path: "/DashboardPage",
            });

          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },



    editAddress() {
      axios({
        method: "POST",
        url: "/deliveryAddress/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curid._id,
          name: this.curid.name,
          email: this.curid.email,
          phone: this.curid.phone,
          address: this.curid.address,
          pincode: this.curid.pincode,
          city: this.curid.city,
          state: this.curid.state,
          landmark: this.curid.landmark,
          routeDescription: this.curid.routeDescription,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addAddress() {
      axios({
        method: "POST",
        url: "/deliveryAddress/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          address: this.address,
          pincode: this.pincode,
          city: this.city,
          state: this.state,
          landmark: this.landmark,
          routeDescription: this.routeDescription,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    validateInput() {
      if (!this.name) {
        this.msg = "Please Provide name";
        this.showSnackBar = true;
        return;
      } else if (!this.email) {
        this.msg = "Please Provide Email";
        this.showSnackBar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please Provide phone number";
        this.showSnackBar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please Provide address";
        this.showSnackBar = true;
        return;
      } else if (!this.pincode) {
        this.msg = "Please Provide pincode";
        this.showSnackBar = true;
        return;
      } else if (!this.city) {
        this.msg = "Please Provide city";
        this.showSnackBar = true;
        return;
      } else if (!this.state) {
        this.msg = "Please Provide state";
        this.showSnackBar = true;
        return;
      } else if (!this.landmark) {
        this.msg = "Please Provide landmark";
        this.showSnackBar = true;
        return;
      } else if (!this.routeDescription) {
        this.msg = "Please Provide route description";
        this.showSnackBar = true;
        return;
      } else {
        this.addAddress();
      }
    },
    getCart() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/cart/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.cart = response.data.cart;
          this.deliveryAddress = response.data.deliveryAddress;
          this.totalAmount = response.data.totalAmount;
          this.amountPayable = response.data.amountPayable;
          this.deliveryCharge = response.data.deliveryCharge;
          this.totalTax = response.data.totalTax;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      axios({
        method: "GET",
        url: "/cart/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.totalAmount = response.data.totalAmount;
          this.amountPayable = response.data.amountPayable;
          this.deliveryCharge = response.data.deliveryCharge;
          this.totalTax = response.data.totalTax;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    editCart(item) {
      axios({
        method: "POST",
        url: "/cart/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          quantity: item.quantity,
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.getData();
          } else {
            console.log("Editing cart failed: " + response.data.msg);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    editDeliveryaddress(item) {
      axios({
        method: "POST",
        url: "/deliveryAddress/current/update",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          deliveryAddressId: item,
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.getCart();
          } else {
            console.log("Editing cart failed: " + response.data.msg);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getAddress() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/deliveryAddress/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          const foundAddressIndex = this.list.findIndex(
            (item) => item.isCurrent === true
          );
          if (foundAddressIndex !== -1) {
            this.selectedAddressIndex = foundAddressIndex;
          }

          const index = this.list.findIndex(
            (item) => item._id === this.deliveryAddress._id
          );

          const foundAddress = this.list.find(
            (item) => item._id === this.deliveryAddress._id
          );
          if (index !== -1) {
            this.selectedAddressIndex = index;
          }

          if (foundAddress) {
            this.deliveryAddress = foundAddress;
            this.showid = foundAddress._id;
          }
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
    
    
<style scoped>
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}

.cus {
  background-color: #13736f;
}

.bg8 {
  background: var(--grend,
      linear-gradient(252deg,
        #e4ecee 0%,
        #9bc2c2 0%,
        #6ca6a5 28%,
        #157470 88%,
        #13736f 100%));
}

.no-uppercase {
  text-transform: none !important;
}

.cus2 {
  background: var(--grend,
      linear-gradient(252deg,
        #e4ecee 0%,
        #9bc2c2 0%,
        #6ca6a5 28%,
        #157470 88%,
        #13736f 100%));
}

.header-cell {
  text-align: center;
  color: black;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 0;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);
}

.data-row {
  border-bottom: 1px solid #f0f0f0;
  padding: 12px 0;
}

.data-cell {
  text-align: center;
  padding-top: 6px;
}

.price {
  color: black;
  font-size: 14px;
  font-weight: 600;
}
</style>